import React, { useEffect, useState } from "react";
import "./../css/login.css";

import { useTranslation } from "react-i18next";
import FileUploadButton from "./inc/FileUploadButton";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Autocomplete from "react-google-autocomplete";
import { Link, useNavigate, useParams } from "react-router-dom";
import i18n from "i18next";
import { IBusinessRegRequest, IBusinessResponseDto } from "../types/business";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "../context/snackbarContext";
import CheckIcon from "@mui/icons-material/Check";
import { capitalizeFirstLetter } from "../utils/Helper";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useApi from "../hooks/useApi";
import businessApi from "../api/business";
import { IResponseModel } from "../types/common";
import useStorage from "../hooks/useStorage";
import { v4 as uuidv4 } from "uuid";
import { AddPhotoAlternate, Close } from "@mui/icons-material";
import GoogleAddressAutocomplete from "./shared/GoogleAddressAutoComplete.tsx";

const OpenNewBusiness = () => {
  const { t } = useTranslation();

  const [businessDetails, setBusinessDetails] = useState<
    IBusinessResponseDto | undefined
  >(undefined);

  const { id } = useParams();
  const navigate = useNavigate();

  const registerBusinessApi = useApi(businessApi.registerBusiness);
  const editBusinessApi = useApi(businessApi.editBusiness);
  const getBusinessDetailsApi = useApi(businessApi.getBusinessDetails);
  const s3StorageService = useStorage();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<IBusinessRegRequest>();

  const [menuImages, setMenuImages] = useState<string[]>(Array(8).fill(""));
  const { showSnackbar } = useSnackbar();

  const handleImageChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const newImages = [...menuImages];
          newImages[index] = reader.result as string; // Add the uploaded image
          setMenuImages(newImages);

          // Update form field with the filtered array
          setValue(
            "menuPics",
            newImages.filter((image) => image) // Exclude empty values dynamically
          );
        };
        reader.readAsDataURL(file);
      }
    };

  const handleRemoveImage = (index: number) => () => {
    const newImages = [...menuImages];
    newImages[index] = ""; // Clear the slot
    setMenuImages(newImages);

    // Update form field with the filtered array
    setValue(
      "menuPics",
      newImages.filter((image) => image) // Exclude empty values dynamically
    );
  };

  const determineMediaType = (file: File): string | null => {
    const allowedExtensions = {
      "1": ["jpg", "jpeg", "png", "gif"], // Image extensions
      "2": ["mp4", "mov", "avi", "mkv"], // Video extensions
    };

    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!fileExtension) return null;

    if (allowedExtensions["1"].includes(fileExtension)) return "1"; // Image
    if (allowedExtensions["2"].includes(fileExtension)) return "2"; // Video

    return null; // Unsupported type
  };
  const onSubmit: SubmitHandler<IBusinessRegRequest> = async (data) => {
    const { connecting, ...requestData } = data;

    // Filter out invalid menuImages
    const filteredMenuImages = menuImages.filter((image) => image);

    // Prepare S3 folder paths
    const phoneNumber = data.businessPhoneNumber.replace(/\+/g, "");
    const menuPicsFolder = `business/${phoneNumber}/menuPics`;

    // Logic for businessLogoFile upload (async)
    const uploadBusinessLogo = async (): Promise<string | undefined> => {
      if (data.businessLogoFile instanceof File) {
        try {
          const mediaType = determineMediaType(data.businessLogoFile);
          if (!mediaType) {
            console.error("Invalid file type for business logo.");
            showSnackbar("Invalid file type for business logo.");
            return undefined;
          }

          const fileUploadResponse = await s3StorageService.handleFileUpload(
            data.businessLogoFile,
            mediaType,
            `business/${phoneNumber}/businessLogo`
          );

          if (fileUploadResponse.success) {
            return fileUploadResponse.url; // Access `url` instead of `location`
          } else {
            console.error("File upload failed:", fileUploadResponse.error);
          }
        } catch (error) {
          console.error("Error uploading business logo:", error);
        }
      }
      return undefined; // Return undefined if upload fails or no file provided
    };

    // Logic for menuPics upload (async)
    const uploadMenuPics = async (): Promise<string[]> => {
      if (filteredMenuImages.length > 0) {
        try {
          const uploadedMenuPics = await Promise.all(
            filteredMenuImages.map(async (base64Image) => {
              const file = base64ToFile(base64Image, `menuPic-${uuidv4()}.png`);
              const mediaType = determineMediaType(file);

              if (!mediaType) {
                console.error("Invalid file type for menu image.");
                showSnackbar("Invalid file type for menu image.");
                return ""; // Skip invalid files
              }

              const menuPicResponse = await s3StorageService.handleFileUpload(
                file,
                mediaType,
                menuPicsFolder
              );

              return menuPicResponse.success ? menuPicResponse.url : ""; // Use `url` instead of `location`
            })
          );

          return uploadedMenuPics.filter((url) => url); // Filter out empty URLs
        } catch (error) {
          console.error("Error uploading menu images:", error);
          return []; // Return empty array if the upload fails
        }
      }
      return []; // Return empty array if no images to upload
    };

    // Start both file uploads concurrently
    const [businessLogoUrl, uploadedMenuPics] = await Promise.all([
      uploadBusinessLogo(),
      uploadMenuPics(),
    ]);

    // Update the requestData with the businessLogoUrl and menuPics
    requestData.businessLogoFile = businessLogoUrl ?? data.businessLogoFile;
    requestData.menuPics = uploadedMenuPics;

    // API request
    try {
      if (id) await editBusinessApi.request(id, requestData);
      else await registerBusinessApi.request(requestData);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  /**
   * Utility function to convert Base64 image data to a File object
   * @param base64Image - Base64 encoded image string
   * @param fileName - Desired name for the file
   * @returns File
   */
  const base64ToFile = (base64Image: string, fileName: string): File => {
    const byteString = atob(base64Image.split(",")[1]);
    const mimeString = base64Image.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }

    return new File([arrayBuffer], fileName, { type: mimeString });
  };

  const businessTypes = [
    { label: "Restaurant", value: "Restaurant" },
    { label: "Coffee Shop", value: "CoffeeShop" },
    { label: "Bar & Pub", value: "Bar/Pub" },
    { label: "Bakery", value: "Bakery" },
    { label: "IceCream & Desserts", value: "IceCream&Desserts" },
    { label: "Super Market", value: "SuperMarket" },
    { label: "Coffee Carts", value: "CoffeeCarts" },
    { label: "Kiosk", value: "Kiosk" },
  ];

  const foodTypeOptions = [
    { label: "Pizza", value: "Pizza" },
    { label: "Burger", value: "Burger" },
    { label: "Sushi & AsianFood", value: "Sushi&AsianFood" },
    { label: "IceCream & Desserts", value: "IceCream&Desserts" },
    { label: "Alcoholic And Cocktails", value: "AlcoholicAndCocktails" },
    { label: "Israeli", value: "Israeli" },
    { label: "American", value: "American" },
    { label: "Italian", value: "Italian" },
    { label: "Salads", value: "Salads" },
    { label: "Thai", value: "Thai" },
    { label: "Mexican", value: "Mexican" },
    { label: "Bukhari", value: "Bukhari" },
    { label: "Tripolitan Or Moroccan", value: "TripolitanOrMoroccan" },
    { label: "Indian", value: "Indian" },
    { label: "Shawarma", value: "Shawarma" },
    { label: "Meats And Appetizers", value: "MeatsAndAppetizers" },
    { label: "Homemade Food", value: "HomemadeFood" },
    { label: "Prepared Food", value: "PreparedFood" },
    { label: "Coffee And Pastries", value: "CoffeeAndPastries" },
    { label: "Sandwiches And Breakfast", value: "SandwichesAndBreakfast" },
    { label: "Falafel", value: "Falafel" },
    { label: "Seafood", value: "Seafood" },
  ];

  const dietaryPreferencesTypesList = [
    { label: "Vegeterian", value: "Vegeterian" },
    { label: "Vegan", value: "Vegan" },
    { label: "GlutenFree", value: "Gluten free" },
    // { label: "None", value: "None" },
  ];

  useEffect(() => {
    if (id) {
      getBusinessDetailsApi.request(id);
    }
  }, []);

  useEffect(() => {
    const businessResponse: any = getBusinessDetailsApi?.data;
    if (businessResponse) {
      if (businessResponse?.data) {
        setBusinessDetails(businessResponse?.data);

        const entries = Object.entries(businessResponse?.data);
        entries.forEach(([key, value]: [any, any]) => {
          setValue(key, value);
        });
      } else {
        showSnackbar(businessResponse?.message || "No business details found");
      }
    }
  }, [getBusinessDetailsApi?.data]);

  useEffect(() => {
    let response: IResponseModel =
      registerBusinessApi?.data ?? editBusinessApi?.data;
    if (response) {
      if (response?.isSuccess) {
        showSnackbar(response?.message);

        const timer = setTimeout(() => {
          navigate("/", { replace: true });
        }, 3000);

        return () => clearTimeout(timer);
      } else {
        showSnackbar(response?.message || "Something went wrong");
      }
    }
  }, [registerBusinessApi?.data, editBusinessApi?.data]);

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FileUploadButton
            control={control}
            errors={errors}
            fileSource={businessDetails?.businessLogoFile ?? undefined}
          />
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginTop: 2,
                color: "#000000",
              }}
            >
              {t("lbl.logo_uploading_guideline")}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginTop: 1,
                color: "#000000",
              }}
            >
              (All fields are required)
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessName}
                >
                  <Controller
                    name="businessName"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.business_name_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter("Business Name")}
                        variant="outlined"
                        fullWidth
                        error={!!errors.businessName}
                        helperText={
                          errors.businessName &&
                          `*${errors.businessName.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessAddress}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Controller
                    name="businessAddress"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.business_address_required") }}
                    render={({ field }) => (
                      <GoogleAddressAutocomplete
                        field={field}
                        setValue={setValue}
                      />
                    )}
                  />
                  {errors.businessAddress && (
                    <span style={{ color: "#FE0000", fontSize: "0.875rem" }}>
                      *{errors.businessAddress.message}
                    </span>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.companyNumber}
                >
                  <Controller
                    name="houseNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: "House number is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter("House Number")}
                        variant="outlined"
                        fullWidth
                        type="phone"
                        error={!!errors.houseNumber}
                        helperText={
                          errors.houseNumber && `*${errors.houseNumber.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.companyNumber}
                >
                  <Controller
                    name="companyNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("lbl.company_number_required"), // Required message
                      minLength: {
                        value: 8,
                        message: "Company number is too short", // Error message for too short
                      },
                      maxLength: {
                        value: 10,
                        message: "Company number is too long", // Error message for too long
                      },
                      pattern: {
                        value: /^[0-9]*$/, // Numeric-only validation
                        message: "Company number is invalid", // Invalid number message
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.company_number"))}
                        variant="outlined"
                        fullWidth
                        type="text" // Use text type for proper input handling
                        error={!!errors.companyNumber}
                        helperText={
                          errors.companyNumber &&
                          `*${errors.companyNumber.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            borderColor: "#E3E3E3",
                            borderWidth: "2px",
                            "& fieldset": {
                              borderColor: "#E3E3E3",
                              borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.companyName}
                >
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.company_name_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.company_name"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.companyName}
                        helperText={
                          errors.companyName && `*${errors.companyName.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.userPhoneNumber}
                >
                  <Controller
                    name="userPhoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "User phone number is required", // Required validation with message
                      minLength: {
                        value: 8,
                        message: "User phone number is too short", // Message for too short
                      },
                      maxLength: {
                        value: 10,
                        message: "User phone number is too long", // Message for too long
                      },
                      pattern: {
                        value: /^[0-9]*$/, // Numeric-only pattern
                        message: "User phone number is invalid", // Invalid format message
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="User Phone Number"
                        variant="outlined"
                        fullWidth
                        type="text" // Use text instead of phone for better compatibility
                        error={!!errors.userPhoneNumber}
                        helperText={
                          errors.userPhoneNumber &&
                          `*${errors.userPhoneNumber.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            borderColor: "#E3E3E3",
                            borderWidth: "2px",
                            "& fieldset": {
                              borderColor: "#E3E3E3",
                              borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessPhoneNumber}
                >
                  <Controller
                    name="businessPhoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("lbl.business_phone_numer_required"), // Required validation with message
                      minLength: {
                        value: 8,
                        message: "Business phone number is too short", // Message for too short
                      },
                      maxLength: {
                        value: 10,
                        message: "Business phone number is too long", // Message for too long
                      },
                      pattern: {
                        value: /^[0-9]*$/, // Numeric-only pattern
                        message: "Business phone number is invalid", // Invalid format message
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(
                          t("lbl.business_phone_number")
                        )}
                        variant="outlined"
                        fullWidth
                        type="text" // Use text instead of phone for better compatibility
                        error={!!errors.businessPhoneNumber}
                        helperText={
                          errors.businessPhoneNumber &&
                          `*${errors.businessPhoneNumber.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px",
                            borderColor: "#E3E3E3",
                            borderWidth: "2px",
                            "& fieldset": {
                              borderColor: "#E3E3E3",
                              borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.contactName}
                >
                  <Controller
                    name="contactName"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.contact_name_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.contact_name"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.contactName}
                        helperText={
                          errors.contactName && `*${errors.contactName.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth margin="normal" error={!!errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("lbl.email_required"),
                      maxLength: 50,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: t("lbl.email_invalid"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("lbl.email")}
                        variant="outlined"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email && `*${errors.email.message}`}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessType}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#FE0000",
                      },
                    }}
                    id="businessType"
                  >
                    Type of business
                  </InputLabel>
                  <Controller
                    name="businessType"
                    rules={{ required: "Type of business is required" }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="businessType"
                        label="Type of business"
                        multiple // Add multiple selection
                        value={field.value || []} // Handle array of selected items
                        sx={{
                          borderRadius: "10px",
                          borderColor: "#E3E3E3",
                          borderWidth: "2px",
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E3E3E3",
                              borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                        onChange={(e) => field.onChange(e.target.value)} // Handle the multiselect change
                        renderValue={(selected) =>
                          selected
                            .map(
                              (value) =>
                                businessTypes.find(
                                  (type) => type.value === value
                                )?.label
                            )
                            .join(", ")
                        } // Display selected items as a comma-separated list
                      >
                        {businessTypes.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            <ListItemText primary={type.label} />
                            <ListItemIcon>
                              {field?.value?.includes(type.value) ? (
                                <CheckIcon sx={{ color: "#FE0000" }} />
                              ) : null}
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.businessType && (
                    <Typography ml={1} fontSize="0.75rem" color="error">
                      *{errors.businessType.message}
                    </Typography>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.foodType}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#FE0000",
                      },
                    }}
                    id="foodType-label"
                  >
                    Type of food/drinks
                  </InputLabel>
                  <Controller
                    name="foodType"
                    control={control}
                    rules={{ required: "Food type is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || []} // Handle array of selected items
                        labelId="foodType-label"
                        label="Type of food/drinks"
                        multiple // Add multiple selection
                        sx={{
                          borderRadius: "10px",
                          borderColor: "#E3E3E3",
                          borderWidth: "2px",
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E3E3E3",
                              borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                        onChange={(e) => field.onChange(e.target.value)} // Handle the multiselect change
                        renderValue={(selected) =>
                          selected
                            .map(
                              (value) =>
                                foodTypeOptions.find(
                                  (type) => type.value === value
                                )?.label
                            )
                            .join(", ")
                        } // Display selected items as a comma-separated list
                      >
                        {foodTypeOptions.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            <ListItemText primary={type.label} />
                            <ListItemIcon>
                              {field?.value?.includes(type.value) ? (
                                <CheckIcon sx={{ color: "#FE0000" }} />
                              ) : null}
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.foodType && (
                    <Typography ml={1} fontSize="0.75rem" color="error">
                      *{errors.foodType.message}
                    </Typography>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.foodDietaryPreference}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#FE0000",
                      },
                    }}
                    id="foodDietaryPreference-label"
                  >
                    Vegetarian, vegan and sensitivities
                  </InputLabel>
                  <Controller
                    name="foodDietaryPreference"
                    control={control}
                    defaultValue={[]} // Set default value as an empty array
                    rules={{ required: "This is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || []} // Handle array of selected items
                        labelId="vegType-label"
                        label="Vegetarian, vegan and sensitivities"
                        multiple // Add multiple selection
                        sx={{
                          borderRadius: "10px",
                          borderColor: "#E3E3E3",
                          borderWidth: "2px",
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E3E3E3",
                              borderWidth: "3px",
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                        onChange={(e) => field.onChange(e.target.value)} // Handle the multiselect change
                        renderValue={(selected) =>
                          selected
                            .map(
                              (value) =>
                                dietaryPreferencesTypesList.find(
                                  (type) => type.value === value
                                )?.label
                            )
                            .join(", ")
                        } // Display selected items as a comma-separated list
                      >
                        {dietaryPreferencesTypesList.map((type) => (
                          <MenuItem key={type.value} value={type.value}>
                            <ListItemText primary={type.label} />
                            <ListItemIcon>
                              {field?.value?.includes(type.value) ? (
                                <CheckIcon sx={{ color: "#FE0000" }} />
                              ) : null}
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.foodDietaryPreference && (
                    <Typography ml={1} fontSize="0.75rem" color="error">
                      *{errors.foodDietaryPreference.message}
                    </Typography>
                  )}
                </FormControl>

                <Typography
                  sx={{
                    color: "#000000",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  Does the business accept Ten bis and Cubis cards?
                </Typography>

                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    name="cardType"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value && value.trim().length > 0
                          ? true
                          : "Please select at least one card type",
                    }}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="Cubis"
                              checked={
                                field.value === "Cubis Card" ||
                                field.value === "Both of them"
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                let newValue;
                                if (isChecked) {
                                  newValue =
                                    field.value === "Ten Bis Card" ||
                                    field.value === "Both of them"
                                      ? "Both of them"
                                      : "Cubis Card";
                                } else {
                                  newValue =
                                    field.value === "Both of them"
                                      ? "Ten Bis Card"
                                      : "";
                                }
                                field.onChange(newValue);
                              }}
                              sx={{
                                color: "#FE0000",
                                "&.Mui-checked": {
                                  color: "#FE0000",
                                },
                              }}
                            />
                          }
                          label="Cubis"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="Ten Bis"
                              checked={
                                field.value === "Ten Bis Card" ||
                                field.value === "Both of them"
                              }
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                let newValue;
                                if (isChecked) {
                                  newValue =
                                    field.value === "Cubis Card" ||
                                    field.value === "Both of them"
                                      ? "Both of them"
                                      : "Ten Bis Card";
                                } else {
                                  newValue =
                                    field.value === "Both of them"
                                      ? "Cubis Card"
                                      : "";
                                }
                                field.onChange(newValue);
                              }}
                              sx={{
                                color: "#FE0000",
                                "&.Mui-checked": {
                                  color: "#FE0000",
                                },
                              }}
                            />
                          }
                          label="Ten Bis"
                        />
                      </>
                    )}
                  />
                </FormGroup>
                {errors.cardType && (
                  <Typography color="error" variant="body2">
                    {errors.cardType.message}
                  </Typography>
                )}

                <Typography
                  sx={{
                    color: "#000000",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  Is the business kosher or not kosher?
                </Typography>
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    name="isKosher"
                    control={control}
                    rules={{
                      required:
                        "Please select if the business is Kosher or Not",
                    }}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        defaultValue=""
                        onChange={(e) => field.onChange(e.target.value)}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              value="true"
                              sx={{
                                color: "#FE0000",
                                "&.Mui-checked": {
                                  color: "#FE0000",
                                },
                              }}
                            />
                          }
                          label="Kosher"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              value="false"
                              sx={{
                                color: "#FE0000",
                                "&.Mui-checked": {
                                  color: "#FE0000",
                                },
                              }}
                            />
                          }
                          label="Not Kosher"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormGroup>
                {errors.isKosher && (
                  <Typography color="error" variant="body2">
                    {errors.isKosher.message}
                  </Typography>
                )}

                <Typography
                  sx={{
                    color: "#000000",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  Uploading pictures of the business menu
                </Typography>
                <Grid container spacing={2}>
                  {menuImages.map((image, index) => (
                    <Grid item xs={6} sm={4} md={3} key={index}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px dashed #ccc",
                          borderRadius: 1,
                          position: "relative",
                          background: image
                            ? `url(${image}) center center / cover`
                            : "#f0f0f0",
                        }}
                      >
                        {!image && (
                          <IconButton
                            component="label"
                            sx={{
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                          >
                            <input
                              type="file"
                              accept="image/*"
                              hidden
                              onChange={handleImageChange(index)}
                            />
                            <AddPhotoAlternate />
                          </IconButton>
                        )}
                        {image && (
                          <>
                            <IconButton
                              component="label"
                              sx={{
                                position: "absolute",
                                right: 4,
                                bottom: 4,
                                backgroundColor: "error.main",
                                width: 24,
                                height: 24,
                                "&:hover": {
                                  backgroundColor: "error.dark",
                                },
                              }}
                            >
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleImageChange(index)}
                              />
                              <AddPhotoAlternate
                                sx={{ color: "white", fontSize: 16 }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={handleRemoveImage(index)}
                              sx={{
                                position: "absolute",
                                right: 4,
                                top: 4,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                width: 24,
                                height: 24,
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                },
                              }}
                            >
                              <Close sx={{ color: "white", fontSize: 16 }} />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <FormGroup sx={{ mt: 2 }}>
                  <Controller
                    name="connecting"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value === true || "You must agree to the terms of use.",
                    }}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={!!field.value} // Ensure the checkbox reflects the correct value
                            onChange={(e) => field.onChange(e.target.checked)} // Update the field's value on change
                            sx={{
                              color: "#FE0000",
                              "&.Mui-checked": {
                                color: "#FE0000",
                              },
                            }}
                          />
                        }
                        label="By connection/joining I agree to the terms of use"
                      />
                    )}
                  />
                  {errors.connecting && (
                    <Typography color="error" variant="body2">
                      {errors.connecting.message}
                    </Typography>
                  )}
                </FormGroup>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <Button
                    sx={{
                      backgroundColor: "#FE0000",
                      textTransform: "capitalize",
                      borderRadius: 10,
                      "&:hover": {
                        backgroundColor: "#FE0000",
                      },
                    }}
                    variant="contained"
                    type="submit"
                    size="large"
                    disabled={
                      !isValid ||
                      registerBusinessApi.loading ||
                      getBusinessDetailsApi.loading
                    }
                    fullWidth
                  >
                    {t("lbl.finish")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={registerBusinessApi.loading || getBusinessDetailsApi.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OpenNewBusiness;
