import axios from "axios";

let apiClient = axios.create();

export const initializeApiClient = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const API_KEY = process.env.REACT_APP_API_KEY;
  apiClient = axios.create({
    headers: accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });

  // Add Axios interceptors for request validation
  apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (API_KEY) {
      config.headers["api-key"] = API_KEY;
    }
    return config;
  });

  // Handle responses for token validation
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        const BACKEND_API_PREFIX = process.env.REACT_APP_API_PREFIX;

        console.error("Unauthorized! Token may be invalid or expired.");

        // Call refreshToken endpoint
        try {
          const refreshResponse = await axios.post(
            `${BACKEND_API_PREFIX}${process.env.REACT_APP_AUTH_REFRESH_TOKEN}`,
            {
              accessToken,
              refreshToken,
            }
          );

          const newAccessToken = refreshResponse.data.accessToken;

          if (newAccessToken) {
            localStorage.setItem("accessToken", newAccessToken);

            // Update the Authorization header in apiClient
            apiClient.defaults.headers.Authorization = `Bearer ${newAccessToken}`;

            // Retry the failed request with the new access token
            error.config.headers.Authorization = `Bearer ${newAccessToken}`;
            return apiClient.request(error.config);
          }
        } catch (refreshError) {
          console.error("Failed to refresh token: ", refreshError);
          // Optionally, handle logout logic here
        }
      }

      return Promise.reject(error);
    }
  );
};

export const reinitializeApiClient = () => {
  initializeApiClient();
};

initializeApiClient();

export default apiClient;
